/* General (global) styling */

// Document
html {
  height: 100%;

  // font-size: $font-size-base;
}

// Body
body {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-x: hidden;
}

// Text
p {
  margin: 0 0 .6rem;

  a {
    font-weight: bolder;
    border-bottom: $border-width solid $yellow;
    transition: $transition-base;
  }
}

main {
  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    color: $gold-medium;
  }

  h4,
  h5,
  h6,
  .h4,
  .h5,
  .h6 {
    color: $gold-dark;
  }
}

// Buttons
.btn {
  font-size: $btn-font-size-sm;
  border: $border-width solid $gray-200;
  border-radius: 0;
  transition: $transition-base;
}

// gt gold chevron
.utility-navigation a::after {
  position: relative;
  bottom: -.1rem;
  display: inline-block;
  width: .78rem;
  height: .9rem;
  margin-left: .1rem;
  content: " ";
  background-image: url("../images/gt-gold-chevron.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
}

.utility-navigation a:hover::after {
  content: " ";
  background-image: url("../images/gt-black-chevron.svg");
}
