@import "custom_bootstrap_vars";

// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors

$white: #ffffff;
$mint-green: #d6e9c6;
$black: #000000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  color: $black;
  background-color: $white;
  border-color: $mint-green;
}

.alert-error {
  color: $red;
  background-color: $pink;
  border-color: $dark-pink;
}
