/* Custom variables and Bootstrap overrides */

/* Global options */
$enable-gradients: true;
$enable-shadows: true;
$enable-responsive-font-sizes: true;

/* Colors (ref: https://brand.gatech.edu/our-look/colors) */
// Grayscale
$white:    #ffffff;
$gray-100: #e8eae7;
$gray-200: #d6dbd4;  // Pi Mile
$gray-300: #a6aeab;
$gray-400: #7b8282;
$gray-500: #54585a;  // Dark Matter
$gray-600: #484b4c;
$gray-700: #3c3e3f;
$gray-800: #313232;
$gray-900: #212529;
$black:    #262626;

// Rainbow palette
$blue:   #2961ff;  // Bright Blue
$indigo: #5f249f;  // Impact Purple
$purple: #7800ff;  // Bright Purple
$pink:   #e83e8c;  // Default pink
$red:    #d83a22;  // New Horizon (Accessible)
$orange: #ff640f;  // Bright Horizon
$yellow: #ffc000;  // Bright Buzz
$green:  #00ec9c;  // Bright Canopy
$teal:   #64ccc9;  // Electric Blue
$cyan:   #00ffff;  // Bright Electric

// Custom
$gold:        #b3a369;  // Tech Gold
$gold-medium: #a4925a;  // Tech Medium Gold (Accessibility)
$gold-dark:   #857437;  // Tech Dark Gold (Accessibility)
$navy:        #003057;  // Tech Navy

// Django Alert colors

// $white:      #ffffff;
$mint-green: #d6e9c6;

// $black:      #000000;
// $pink:       #f2dede;
$dark-pink:  #eed3d7;

// $red:        #b94a48;

// Theme mapping
$primary:   $gold;
$secondary: $gray-500;
$success:   $green;
$info:      $navy;
$warning:   $orange;
$danger:    $red;
$light:     $gray-100;
$dark:      $gray-800;

/* Elements */
// Body
$body-bg:    $white;
$body-color: $black;

// Links
$link-color:                              $body-color;
$link-decoration:                         none;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   none;
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
$paragraph-margin-bottom: 1.3rem;

// Containers
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140p
// );

// Transitions
$transition-base: all .3s ease-in-out;

/* Typography */
// Custom fonts
@import "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
@import "https://use.typekit.net/ela2mmm.css?family=Din:400,400italic,600,600italic,700,700italic";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css";

$font-family-roboto:      "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$font-family-roboto-slab: "Roboto Slab", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$font-family-din-2014:    "din-2014", sans-serif;

// Default mapping
$font-family-base:        $font-family-roboto;

/* Navbar */
$navbar-dark-color:                 rgba($white, .9);
$navbar-dark-toggler-border-color:  rgba($white, .8);

////////////////////////////////
// Alerts //
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  color: $black;
  background-color: $white;
  border-color: $mint-green;
}

.alert-error {
  color: $red;
  background-color: $pink;
  border-color: $dark-pink;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
}

#calendar {
  max-width: 1100px;
  margin: 40px auto;
}
