/* Footer section styling */

// GT footer
#gt-footer {
  background-color: $gold-medium;

  // Text
  p,
  a,
  h3 {
    display: block;
    margin-bottom: .4rem;
    font-size: .85rem;
    font-weight: 500;
    line-height: 1.3;
    color: $black;
    border: none;
  }

  h3 {
    font-size: .9rem;
    font-weight: 600;
  }

  // Links
  a {
    &:hover,
    &:focus {
      color: $black;
      text-decoration: underline;
      background-color: transparent;
    }
  }

  // Lists
  ul {
    padding-left: 0;
    list-style: none;
  }

  // Logo
  svg {
    height: 2rem;
    margin-bottom: $spacer;
    fill: $white;
  }

  // Social icons
  .social-links {
    .list-inline-item {
      padding: .2em .3em;
      margin-right: 0;
    }
  }

  // Borders
  .bordered {
    padding-left: $spacer;
    border-left: $border-width solid $white;

    // Hide column borders on mobile
    @include media-breakpoint-down(sm) {
      border-left: none;
    }
  }

  // Shrink space between footer links on mobile
  li {
    a {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
}
