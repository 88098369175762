/* Header section styling */

// GT header
#gt-header {
  // Banner container
  .banner {
    background-color: $primary;

    @include gradient-x(darken($primary, 8%), $primary);

    .navbar {
      // Conflict fix: don't apply some of the Bootstrap styling to the banner navbar
      min-height: unset;
      padding: unset;
      margin-bottom: unset;
      background-image: none;
      border: unset;
      border-radius: unset;
    }
  }

  // Banner logo
  .navbar-brand {
    // Conflict fix: don't apply Bootstrap logo dimensions
    float: unset;
    height: unset;
    padding: unset;
    line-height: unset;

    // Logo
    svg {
      height: 4.5rem;
      padding: $spacer;
      fill: $white;
    }
  }

  // Site info
  .site-info {
    padding-top: $spacer;
    padding-bottom: $spacer;
    font-family: $font-family-din-2014;
    color: $navy;
    font-display: swap;

    .site-name {
      margin: 0;
      font-size: 2.375rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.01;

    }

    .site-slogan {
      margin: .25rem 0 0 .2rem;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.1;
    }
  }
}
